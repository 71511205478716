.blackBar{
    background-color: #393E40;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
    padding: 0 1.5rem;
    height: 8vh;
}

.sideFirstRow{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 1.5rem;
}

.svText{
    padding: 0 1rem;
}

.sideImage{
    object-fit: cover;
    height: 13vh;
}

.textSV{
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
}

.SVcontactDetails{
    background-color: #EEEEEE;
    text-align: center;
    color: #2A2F33;
    font-size: .9rem;
    font-weight: 500;
    padding: .3rem;
}

.nameTextSV{
    font-size: .8rem;
    margin: 0;
}

.priceSV{
    margin: 0;
    font-size: .85rem;
    color: #8FAE1B;
    line-height: 2rem;
}

.QtyformSV {
    width: 4rem;
    cursor: pointer;
}

.removeText{
    font-size: .8rem;
    color: #41630F;
    padding-top: 5rem;
    font-weight: bold;
}

.textSV p{
    color: #2A2F33;
    font-size: .97rem;
}

.totalTextSV{
    font-weight: bold;
    color: #2A2F33;
}

.totalPriceSV{
    color:#41630F;
    font-weight: bold;
}

.buttonSV{
    display: flex;
    justify-content: space-between;
    padding-top: 8rem;
}