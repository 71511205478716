.EmptyCart span{
    color: #41630F;
}

.empty{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem 0rem 7rem;
}

.empty img{
    width: 18vw;
}

.empty p, .empty h4{
    margin: 0;
}

.pathP{
    padding: 0 2rem;
    margin: 0;
}

@media screen and (max-width:768px){
    .EmptyCart p{
        font-size: 1.5rem;
    }

    .empty img {
        width: 30vw;
    }

    .empty h3{
        font-size: 1.8rem;
    }

}

@media screen and (max-width:500px){
    .EmptyCart p {
        font-size: 1rem;
    }

    .empty h3 {
        font-size: 1rem;
    }

    .empty img {
        width: 40vw;
    }
    .pathP{
        padding: 0 0.7rem;
    }
}