.ShoppingCart{
    padding: 0 2rem 4rem;
    display: flex;
    flex-direction: column;
}

.headding h2{
    font-size: 1.5rem;
    color: #393E40;
    font-weight: bold;
}

.tables{
    background-color: #F5F5F5;
}

table img{
    object-fit: cover;
}

.firstTable{
    width: 91vw;
    padding: 2rem;
}
.alignTbl{
    width: 55%;
}
.alignTbl h4{
    padding-left: 1rem;
    font-weight: 600;
    margin: 0;
}
.alignTblh2{
   font-size: 1.1rem;
   font-weight: 600;
}
.firstTable th, td{
    padding: 1rem;
    vertical-align: middle;
}
.tblRow, .tblRow2{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.alignTbl p{
    font-size: 0.9rem;
    padding: 0 0.5rem;
}
th{
    font-size: 1rem;
}
td{
    font-size: 0.9rem;
}
.fstRow{
    background-color: white;
    height: 3rem;
}

.scndRow{
    background-color: #EEEEEE;
}

.third{
    background-color: white;
}

.secondTable{
    display: flex;
    justify-content: flex-end;
    padding: 0rem 1rem;
}

.tblRow{
    display: flex;
    justify-content: space-between;
    background-color:#EEEEEE ;
}

.tblRow2{
    display: flex;
    justify-content: space-between;
    background-color:#FFFFFF ;
}

.buttns{
    display: flex;
    justify-content: flex-end;
    padding-top: 2rem;
}

.btns{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 14rem;
}

.clearIcn{
    color: #c30d0dab;
    cursor: pointer;
}

@media screen and (max-width:820px){
    .headding p{
        font-size: 1rem;
    }
    .tableOverFlow{
        overflow: auto;
    }
    .firstTable {
        padding: 2rem 0.5rem;
    }
    .ShoppingCart {
        padding: 0 2rem 2rem;
    }
    .btns{
        width: 100%;
        padding: 0rem 0.5rem 3rem;
    }
}
@media screen and (max-width: 700px){
    .alignTbl{
        width: 100%;
    }
}
@media screen and (max-width: 500px){
    .secondTable{
        padding: 0 ;
    }
    .headding p{
        font-size: 1rem;
    }

    .tableOverFlow{
        overflow: auto;
    }
    .ShoppingCart {
        padding: 0 0.7rem 4rem;
    }
    .space{
        padding: 0.4rem;
    }
}
