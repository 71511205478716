.background{
    display: flex;
    height: 85vh
}

.greenScreen{
    background-color: #41630F;
}

.greenScreen, .backImg{
    width: 50%;
}

.backImg{
    object-fit:cover;
    background-size:cover;
    background-image: linear-gradient(to left,rgba(86, 116, 128, 0.233),rgba(37, 15, 34, 0.425)),url(/src/components/Home/images/backPic.webp);
}

.text h2 {
    padding: 20vh 0 0 8vw;
    color: #FFFFFF4D;
    font-size: 3rem;
    margin: 0;
}


.content {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding-left: 13rem;
}

.content h2{
    font-size: 2.7rem;
    margin: 1rem 0;
    color: #f0eeeee1;
}

.content p{
    font-size: 1rem;
    width: 71%;
    color: #f0eeee;
    line-height: 1.4rem;
}

.productPic{
    position: absolute;
    top: 73vh;
    left: 38rem;
}

.productPic img{
    object-fit: contain;
    padding: .3rem;
}

@media screen and (max-width:1360px){
    .productPic{
        display: none;
    }
}

@media screen and (max-width:768px){
    .background {
        height: 57vh;
    }

    .productPic {
        top: 39rem;
        left: 17rem;
    }

    .text h2 {
        padding: 0 0 0 8vw;
        font-size: 3.8rem;
    }
    .text p {
        padding: 15vh 0 0 8vw;
        font-size: 1.2rem;
    }

    .content {
        padding-left: 9rem;
    }

    .content p {
        font-size: 1.4rem;
    }
}

@media screen and (max-width:475px){
    .background {
        height: 54vh;
    }

    .text h2{
        font-size: 2rem;
        padding: 0 0 0 8vw;
    }
    .text p{
        font-size: 1rem;
        padding: 10vh 0 0 8vw;
    }

    .content {
        padding-left: 4rem;
    }

    .content h2 {
        font-size: 1.5rem;
    }

    .content p {
        font-size: .8rem;
        margin-top: 0;
        width: 74%;
        line-height: 1.1rem;
    }

    .imgNone{
        display: none;
    }

    .productPic {
        top: 24rem;
        left: 10rem;
    }

    .productPic img {
        width: 6rem;
        height: 4rem;
    }

}
