.scrollbtn{
    position: fixed;
    z-index: 5;
    bottom: 1.3rem;
    right: 3rem;
    height: 2.8rem;
    width: 2.8rem;
    border: solid 1px #41630F;
    border-radius: 55%;
    background-color: #ffffff;
    padding: 0;
    margin: 0;
}
.chatIcon{
    color: #41630F;
    padding: 0.5rem;
    font-weight: 700;
    font-size: 1.7rem;
    margin: 0;
}
@media (max-width: 800px) {
    .scrollbtn{
        bottom: 1rem;
        right: 2rem;
        height: 4rem;
        width: 4rem;
    }
    .chatIcon{
        font-weight: 500;
        font-size: 2rem;
    }
}
@media (max-width: 500px) {
    .scrollbtn{
        bottom: 0.6rem;
        right: 0.8rem;
        height: 2.5rem;
        width: 2.5rem;
    }
    .chatIcon{
        font-weight: 500;
        font-size: 2rem;
        padding: 0.4rem;
    }
}