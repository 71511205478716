.feedBack{
    background-color: #F5F5F5;
}
.inputTextP{
    font-size: 0.9rem;
}
.feedback_bg{
    padding:0 4rem 3rem;
    margin: 0;
}

.feedForm{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.5rem 8rem;
}

.feedPath{
    margin: 0;
}

.feedRadioBtn{
    display: flex;
    padding: 1rem 0rem;
    font-size: 0.9rem;
}

.feedBtn{
    display: flex;
    padding: 1.5rem 0rem;
}
.feedbackBtn{
    margin-right: 1rem;
}

.radio{
    width: 10rem;
}

.radioRequest{
    width: 12rem;
}

@media screen and (max-width:820px){
    .feedback_bg{
        padding:0 2rem 3rem;
    }
    .feedRadioBtn{
        flex-direction: column;
    }

    .feedForm {
        padding: 2rem 1rem;
    }

}

@media screen and (max-width: 475px){
    .feedForm {
        padding: 2rem 1rem;
    }
    .feedback_bg{
        padding: 1rem;
    }
    .feedPath {
        padding: 1rem 0.7rem;
    }
    .feedback_bg{
        padding:0 0.7rem 3rem;
    }
    
}