.error{
    display: flex;
    justify-content: center;
    text-align: center;
}  

.errorAlign{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0rem 4rem
}

.errorAlign img{
    width: 30vw;
}

.errorAlign h3{
    font-size: .9rem;
    margin: 0;
    line-height: 1.3rem;
}

.backtoButton{
    padding-top: 2.3rem;
}

@media screen and (max-width:768px){
    .errorAlign img {
        width: 55vw;
    }

    .errorAlign h3 {
        line-height: 1.7rem;
        font-size: 1.3rem;
    }

}

@media screen and (max-width:575px){
    .errorAlign img {
        width: 75vw;
    }

    .errorAlign h3 {
        line-height: 1.3rem;
        font-size: .9rem;
    }

    .backtoButton {
        padding-top: 2rem;
    }
}