.aboutCompany{
    display: flex;
    justify-content: center;
    padding: 2rem 0;
}

.companyDetails{
    width: 50%;
}

.about-Container{
    background-color: #ffffff;
}

.flexdiv{
    display: flex;
    align-items: center;
}

.flexdiv p{
    font-size: .8rem;
    line-height: 1.2rem;
}

.companyIcons{
    padding-right: 1.5rem;
    color: #41630F;
}

.mailC{
    color: #41630F;
}

.open{
    color: #1FEE2D;
}
.close{
    color: red;
}
@media screen and (max-width:788px){
    .companyDetails{
        width: 80%;
    }

    .flexdiv p{
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .form-search {
        width: 20rem;
    }
}

@media screen and (max-width:580px){
    .form-search {
        width: 6rem;
        padding-left: 1rem;
    }
    .companyDetails{
        width: 88%;
    }

    .flexdiv p{
        font-size: 1rem;
        line-height: 1.3rem;
    }
}

@media screen and (max-width:475px){

    .flexdiv p{
        font-size: .8rem;
         line-height: 1rem;
    }
}


