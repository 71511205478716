.thank{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.topText{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 2.5rem;
}
.picTextPricep{
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
.picTextPricep img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.picTextPricepimg{
    width: 25%;
    height: auto;
    padding-top: 1rem;
    object-fit:cover;
}
.chekIcon{
    color: #41630F;
}

.topText h2{
    color: #29292A;
    font-size: 2.5rem;
    line-height: 0;
}

.topText p{
    margin: 0;
    color: #2A2F33;
    font-size: .8rem;
    font-weight: 400;
}

.mainDiv{
    background-color: #F7F7F7;
    display: flex;
    justify-content: center;
    justify-items: center;
    width: 100%;
}
.mainDiv2{
    background-color: #F7F7F7;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-items: center;
    width: 67%;
}
.orderConfrm{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 54%;
    margin: 5rem 0 3rem 0;
}

.confirmation{
    display: flex;
    background-color: #ECEFF1;
    justify-content: space-between;
    color: #2A2F33;
}

.confirmationh2{
    padding: 1rem;
    margin: 0;
    font-size: 1rem;
}
    
.confirmationp{
    font-size: .9rem;
}

.cusInfo{
    padding: 1rem;
}

.cusInfo h3{
    font-size: 1rem;
}

.cusInfo h4{
    color: #29292A;
    font-size: .9rem;
}
.cusInfo p{
    color: #29292A;
    font-size: .8rem;
}
.alignc{
    width: 30%;
}

.contacInfo, .addresses{
    display: flex;
    justify-content: space-between;
    align-content: flex-end;
}

.odrSummary{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 44%;
    margin: 5rem 0 3rem 0;
}

.Summaryor{
    padding: 1rem;
    margin: 0;
    font-size: 1rem;
}
.subdetails{
    font-size: 0.8rem;
}
.Summaryh2{
    font-size: 0.8rem;
}
.picTextPrice{
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    flex-direction: row;
    color: #29292A;   
    align-items: flex-start;
    
}

.lkrPrice{
    color: #29292A;
    font-size: .8rem;
    font-weight: 600;
}
.Summaryp{
    color: #29292A;
    font-size: .8rem;
}
.picTextPrice span{
    color: #41630F;
}

.subTotal, .Shipping,  .grandTotal{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.grandTotal h3 ,.grandTotal h4{
    margin: 0;
    padding: 0;
    font-size: 1.1rem;
}

.ContinueShoppingBtn{
    display: flex;
    justify-content:center;
    padding-bottom: 2rem;
    background-color: #F7F7F7;
    width: 100%;
}
@media screen and (max-width:900px){
    .picTextPrice{
        flex-direction: column;
    }
    .picTextPricep{
        width: 100%;
    }
    .mainDiv2{
        width: 90%;
    }
}
@media screen and (max-width:600px){
    .mainDiv2{
        width: 90%;
        flex-direction: column;
    }
    .orderConfrm{
        width: 100%;
        margin: 3rem 0;
    }
    .odrSummary{
        width: 100%;
        margin: 0 0 3rem 0;
    }
 
}

