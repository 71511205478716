.wishList-grid{
    display: flex;
    justify-content: center;
    padding: .5rem 3rem;
    align-items: flex-start;
    flex-direction: row;
}
.wishList-div{
    width: 100%;
}
.wishList-heading1{
    font-size: 1rem;
    color: #000000; 
    font-weight: bold;
    margin: 0;
    line-height: 1rem;
    padding: 1.5rem 0rem 1rem;
}
.cardHeading{
    font-size: 1.2rem;
    color: #000000; 
    font-weight: bold;
}
.cardRight-Text{
    font-size: .85rem;
    font-weight: 500;
}
.wishListBtn:focus {
    background-color: #41630F;
    color: #FFFFFF;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.wishListBtn{
    background-color: #F5F5F5;
    color: #29292a;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #FFFFFF;
}
.btnP{
    padding-left: .5rem;
    padding-left: 0.5rem;
    font-size: .84rem;
    font-weight: 500;
}
.buttonDiv{
    padding-top: 1.5rem;
}

@media screen and (max-width:768px){
    .wishList-grid {
        padding: 0.5rem 1rem;
        flex-direction: column;
        justify-content: flex-start;
    }
    .cardHeading{
        font-size: 1rem;
    }

}