.EmptyCart span{
    color: #41630F;
}

.emptyWL{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom:2rem;
}

.emptyWL img{
    width: 16vw;
}

.emptyWL p, .emptyWL h4{
    margin: 0;
}


@media screen and (max-width:768px){
    .EmptyCart p{
        font-size: 1.5rem;
    }

    .emptyWL img {
        width: 30vw;
    }

    .emptyWL h3{
        font-size: 1.8rem;
    }

}

@media screen and (max-width:500px){
    .EmptyCart p {
        font-size: 1rem;
    }

    .emptyWL h3 {
        font-size: 1rem;
    }

    .emptyWL img {
        width: 40vw;
    }
}