.greenLineIS {
    height: 0.5rem;
    width: 97%;
    background-color: #8fae1b;
}

.conformDetailsIS {
    width: 97%;
}

.leftFirstGIS {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding-bottom: 1.5rem;
}

.pinIcnTextIS {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.conformIS {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.PinIconIS {
    color: #8fae1b;
}

.pinIcnTextISp {
    font-size: 0.9rem;
    font-weight: 600;
    color: #000;
    padding-left: 0.5rem;
}


.InStockMain {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
}

.InStockMainpath {
    display: flex;
    width: 95%;
}

.pagepathin2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.leftdivIS {
    width: 62%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.rightdivInS {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.inmimg {
    width: 18rem;
    height: 18rem;
}

.inmimg2 {
    width: 4.3rem;
    height: 4.3rem;
}

.rowGinStock {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}

.leftSecondInS {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    width: 45%;
}

.inStockBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 13rem;
}

.ISrderImages2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 22%;
    height: 18rem;
}

.mainISimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: #FFFFFF 1.5px solid;
}

.mainISimg:hover {
    border: #41630F 1.5px solid;
    cursor: pointer;
}

.ISimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ISimg:hover {
    border: #41630F 1.5px solid;
    cursor: pointer;
}

.leftThirdInS {
    width: 46%;
}

.ISname {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.ISname h4 {
    margin: 0;
}

.InSsub {
    font-size: 0.9rem;
}

.GreenTextIS {
    margin: 0;
    color: #8fae1b;
    font-size: 0.9rem;
}

.InSsub span,
.IconInS {
    color: #41630F;
    font-size: 0.9rem;
}

.greenTextIS {
    color: #8fae1b;
}

.QuantityIS {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 7rem;
}

.QtyformIS {
    width: 4.6rem;
}

.bDescription {
    text-align: start;
    padding: 1.5rem;
    padding-top: 6rem;
}

.rightBg-colorInS,
.right-DescriptinInS {
    background-color: #F5F5F5;
    padding: 0.7rem;
}

.deliveryh4InS {
    margin: 0;
    font-size: 0.8rem;
}

.IconInS2 {
    padding-top: 1rem;
    padding-right: 1rem;
    color: #41630F;
}

.IconInS {
    padding-right: 1rem;
}

.dLocationIS {
    display: flex;
    align-items: center;
}

.deliveryDetailInS {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.deliveryPaymentInS {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.deliFlex {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.maint {
    font-size: 0.9rem;
}

.subt {
    font-size: 0.8rem;
}

.storeTextInS {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.storeInS {
    color: #41630F;
}

.storeDetailsInS {
    width: 100%;
    margin-top: 1rem;
}

.mgbottom {
    margin-bottom: 1rem;
}

.pagepathin {
    padding-left: 4rem;
}

@media screen and (max-width:820px) {
    .leftFirstGIS {
        align-items: center;
    }

    .InStockMain {
        flex-direction: column;
    }

    .rightdivInS {
        width: 90%;
    }

    .leftdivIS {
        width: 100%;
    }

    .InStockMain {
        align-items: center;
        justify-content: center;
    }

    .pagepathin {
        padding-left: 2rem;
    }

    .bDescription {
        padding: 1.5rem;
    }
}

@media screen and (max-width:500px) {
    .conformIS {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media screen and (max-width:420px) {
    .rowGinStock {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .leftdivIS {
        width: 95%;
    }

    .leftSecondInS {
        width: 95%;
    }

    .leftThirdInS {
        width: 90%;
        padding-top: 2rem;
    }

    .pagepathin {
        padding-left: 1.5rem;
    }
}