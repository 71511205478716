.companyMain{
    display: flex;
    flex-direction: column;
}
.greenField{
    background-color: #41630F;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 2.5rem;
    color: #ffffffc7;
}
.learnmorebtn{
    display: block;
}
.companyPic{
    height: 25vh;
    object-fit: cover;
    width: 100%;
}
.relativestore{
    position: relative;
    width: 100%;
}
.companyText{
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.315);
    height: 25vh;
}

.h1Text{
    font-size: 2.5rem;
    margin: 0;
}
.addressP{
    font-size: 1rem;
}
.IconBtn-search{
    background-color: #41630F;
    width: 2rem;
    height: 2rem;
}
.form-searchbar{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0.5rem;
}
.whitePrice{
    color: #ffffff;
}
.greenField p{
    text-align: center;
    padding: 0 0.5rem;
}

@media screen and (max-width:820px){
    .bgImageText{
        height: 17vh;
    }
    .addressP {
        font-size: 0.9rem;
    }
    .h1Text{
        font-size: 2rem;
    }
}

@media screen and (max-width:560px){
    .learnmorebtn{
        display: none;
    }
    .bgImageText {
        height: 12vh;
    }
    .greenField{
        font-size: .8rem;
    }
    .addressP {
        font-size: .8rem;
    }
    .h1Text {
        font-size: 1.5rem;
    }
}
