.Subscribe{
    padding: 5rem;
}

.subsDetail{
    background-color: #41630F;
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subAlign{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 10rem;
}

.subsDetail h2{
    font-size: 1.5rem;
}

.subsDetail h3{
    font-size: 1.3rem;
    color: white;
    line-height: 0;
    font-weight: 500;
}

.subsDetail h2 {
    color: white;
    line-height: 0;
    font-weight: 500;
}
.data{
    width: 100%;
}
.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.email-form{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.data button{
    height: 2rem;
}

.MuiInputBase-root{
    height: 2rem;
    background-color: white;
}

.mailfield{
    width: 70%;
}

.subsDetail p {
    font-size: 0.7rem;
    color: red;
    display: block;
}
@media screen and (max-width:768px){
    .email-form{
        flex-direction: column;
        align-items: center;
    }

    .Subscribe{
        padding: 2rem;
    }

    .subsDetail h2 {
        font-size: 1.5rem;
    }

    .subsDetail{
        height: 18rem;
    }

}

@media screen and (max-width:655px){
    .Subscribe {
        width: 100%;
        padding: 0;
        margin-top: 4rem;
    }

    .subsDetail h2{
        font-size:  0.9rem;
    }

    .subsDetail {
        height: 14rem;
    }

    button {
        margin: 0;
    }

    .mailfield{
        width: 65vw;
    }
}
