.Checkout {
    display: flex;
    flex-direction: row;
    justify-Content: space-around;
    align-items: flex-start;
    margin-bottom: 3rem;
}

.form-Itemdiv {
    width: 45%;
}

.summaryRow {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.loginText,
.crabs,
.loginLostT {
    color: #41630F;
}

.loginText {
    cursor: pointer;
}

.formTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
}
.summaryRowPic {
    width: 30%;
    height: auto;
}

.summaryRowPic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.formBackground {
    background-color: #F5F5F5;
    margin-bottom: 2rem;
}

.shippingBackground,
.OrderSummarybg {
    background-color: #F5F5F5;
    padding: 1rem;
}

.inputTextP {
    font-Size: .9rem;
    margin: 0;
    padding-top: 1.5rem;
}

.summary-Itemdiv {
    width: 45%;
}

.billingInfoH3,
.OdrSummaryH3 {
    margin: 0;
    color: #393E40;
    font-size: 1.1rem;
}

.OdrSummaryH3 {
    padding: 1.5rem 0;
}

.checkboxMailing {
    padding: 1.5rem 0;
}

.standardRadio {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
}

.SLtextRadio {
    display: flex;
    justify-content: space-between;
    width: 35rem;
}
.checkitemdes{
    height: auto;
}

.CategorySummaryCheck {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.subTotalcls,
.shippingCls,
.TotalCls {
    display: flex;
    justify-content: space-between;
}

.greenText {
    font-size: .85rem;
    color: #8FAE1B;
    margin-top: 0;
}

.totalShippingPrice {
    font-size: .75rem;
    font-weight: 600;
}

.inputTextP span,
.loginStart {
    color: red;
}

.login_Row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 17rem;
    padding-top: 1rem;
}

.checkLoginText,
.loginLostT {
    font-size: .9rem;
    font-weight: 500;
    line-height: 0;
}

.billingInfoP {
    margin: 0;
    color: #393E40;
    font-size: 1.1rem;
}

.OStextH4 {
    font-size: 0.8rem;
    font-weight: 600;
    color: #29292A;
    margin-top: 0;
}

.totalShippingT {
    font-size: .8rem;
    font-weight: 600;
    color: #29292A;
}

.OStextP {
    font-size: 0.75rem;
    margin-top: 0;
}

.TotalCls {
    font-size: 1.1rem;
    color: #29292A;
}

.OStextPmar {
    padding-bottom: 1.5rem;
}

.stylelog {
    font-size: 0.8rem;
}

.stylelog span {
    color: #41630F;
}

.stylelog:hover {
    cursor: pointer;
    color: #41630F;
}
@media screen and (max-width:1150px) {
    .summaryRow {
        width: 60%;
    }
}
@media screen and (max-width:820px) {
    .summaryRowPic {
        width: 20%;
        height: auto;
    }
    .Checkout {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .form-Itemdiv,
    .summary-Itemdiv {
        width: 90%;
    }
    .form-Itemdiv{
        order: 2;
    }
    .summaryRow{
        width: 100%;
    }
    .CategorySummaryCheck {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

}

@media screen and (max-width:550px) {}