.itemcard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 3px 3px 3px #EEEEEE;
    width: 100%;
}

.imgdiv {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.imgdiv2 {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 2;
}
.relative {
    position: relative;
    width: 100%;
    height: 15rem;
  } 
  .relative:hover {
    position: relative;
    height: 15rem;
    cursor: pointer;
  } 
.imgcarddiv {
    object-fit: cover;
    height: 15rem;
    width: 100%;
}
.imgcarddiv2 {
    object-fit: cover;
    height: 15rem;
    width: 100%;
    opacity: 0.5;
}

.itemicondiv {
    padding: 0.4rem 0 0rem 1rem;
    background: none;
    border: none;
}
.itemicondiv:hover {
    cursor: pointer;
}

.itemicon {
    padding: 0.2rem;
}
.iconp{
    margin: 0;
}

.itemcard2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 1rem;
    height: 14rem;
}

.itemweight {
    color: #29292A;
    font-weight: bold;
    font-size: 0.9rem;
    width: 90%;
    margin: 0;
}

.itemprice {
    color: #29292A;
    font-weight: bold;
    font-size: 0.9rem;
    width: 80%;
    line-height: 2rem;
    margin: 0;
}

.categoryname {
    color: #29292A;
    font-size: 0.85rem;
    line-height: 2rem;
    margin: 0;
}

.categorystore {
    color: #29292A;
    font-size: 0.85rem;
    line-height: 2rem;
    margin: 0;
    font-style: italic;
}

.itemicon {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 6px;
    color: #41630F;
}