.thead{
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.125rem;
    color: rgb(61, 66, 74);
    padding:.5rem 2rem;
    text-align: start;
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}
.tprodut1{
    width: 5rem;
}
.tname{
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.125rem;
    color: rgb(61, 66, 74);
    padding:.5rem 2rem;
    text-align: start;
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}
.tableProduct{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: 15rem;
}
.cartp1 {
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.125rem;
    color: rgb(61, 66, 74);
    padding: 2rem .5rem .5rem 2rem;
    text-align: start;
}
.cartp2{
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.125rem;
    color: rgb(61, 66, 74);
    padding:.5rem 2rem;
    text-align: start;
}
.cartTotal{
    background: #EEEEEE;
    margin: 1rem;
}