.testimonial{
    text-align: center;
    padding: 3.43rem;
}
.pharagraph{
    display: flex;
}
.pharagraphp{
    font-size: 0.9rem;
    text-align: center;
    color: #29292A;
    line-height: 1.5rem;
}
.com2{
    display: flex;
    align-items: flex-end;
}
.com1 img, .com2 img{
    width: 1.5rem;
    margin: 0rem 0.7rem;
}
.boyImg img{
    width: 6.5rem;
    padding: 1.87rem;
}
.name{
    text-align: center;
}

.nameh4{
    font-size: 1.3rem;
    margin: 0;
    padding: 0;
    color: #393E40;
}

.namep{
    font-size: 0.9rem;
}

@media screen and (max-width:768px){
    .testimonial {
        padding: 2.43rem;
    }
    .com1 img, .com2 img {
        width: 1.5rem;
    };
}

@media screen and (max-width:475px){
    .pharagraph{
        padding-top: 0rem;
    }
    .com1 img, .com2 img {
        width: 1rem;
    }
} 