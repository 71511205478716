.welcome{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-image: url(../images/welcome.jpg);
    background-size: cover;
    padding: 5rem 0;
    background-position: center;
}
.introAndStepper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 45%;
}
.introduction{
    width: 78%;
    text-align: end;
    color: white;
    font-size: 1rem;
    line-height: 1.4rem;
}
.introductionh{
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 1.8rem;
}
.introductionh2{
    color:#41630F;
    font-weight: 600;
}
/* .welcome{
    padding: 5rem 0rem;
}

.align{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.introAndStepper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    background-image: url(../images/welcome.jpg);
    background-size: cover;
    padding: 4rem 0;
}

.introduction{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: aliceblue;
}

.stepper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6rem  0 6rem 3rem ;
}

.introduction h3{
    font-size: 1.7rem;
}

.introduction p{
    width: 28rem;
}

.introduction span{
    color:#41630F;
}

.css-qivjh0-MuiStepLabel-label { 
    color: white;
}

.css-qivjh0-MuiStepLabel-label.Mui-active {
    color: rgb(255, 255, 255);
} */
@media screen and (max-width:1400px){
    .introAndStepper{
        width: 55%;
    }
}
@media screen and (max-width:768px){
    .introAndStepper{
        flex-direction: column;
        width: 80%;
    }
    .introduction{
        width: 100%;
        text-align: center;
    }
}

@media screen and (max-width:475px){
    
}  