.cirnum{
    background-color: aliceblue;
    border-radius: 50%;
    width: 0.9rem;
    padding: 0.2rem;
    font-size: 0.8rem;
}
.Lorem{
    color: white;
}
.step{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 8.5rem;
}
.stepline{
    height: 1.5rem;
    width:  1px;
    background-color: white;
}
.stepline2{
    width: 0.6rem;
    background-color: none;
    height: 1.5rem;
}
.stepline2div{
    display: flex;
    flex-direction: row;
}
.stepline3{
    width: 0.6rem;
    background-color: white;
    height: 1px;
    margin-left: 0.3rem;
    margin-top: 2px;
    margin-bottom: 2px;
}