.main{
    padding: 2rem;
}

.rowText{
    display: flex;
    justify-Content:space-between;
    align-Items:center;
    padding: 2rem;
}

.rowText h2{
    color: #393E40;
    font-size: 1.4rem;
}

.rowText p{
    color: #41630F;
    font-size: 1rem;
}

.card img{
    object-fit: contain;
}
.showAll {
    cursor: pointer;
}
@media screen and (max-width:768px){
    .main {
        padding: 2rem 1.5rem 6rem;
    }

    .rowText{
        padding: 1rem;
    }

    .rowText h2 {
        font-size: 2rem;
    }

    .rowText p {
        font-size: 1.6rem;
    }

}

@media screen and (max-width:475px){
    .main {
        padding: 1.5rem .7rem 1rem;
    }

    .rowText{
        padding: 1.5rem 0;
    }

    .rowText h2 {
        font-size: 1.2rem;
    }

    .rowText p {
        font-size: .98rem;
    }

}
