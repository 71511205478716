body {
    margin: 0;
    padding: 0;
  }
.mainfdiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ECEFF1;
}
.submainfdiv{
    display: flex;
    padding: 2.5rem 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 85%;
}
.subonediv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 50%;
}
.footer{
    background-color: #ECEFF1;
}

.footerTextP{
    font-size: .9rem;
    font-weight: 500;
    color: #212121;
    line-height: .9rem;
}
.footernavigate{
    font-size: .9rem;
    font-weight: 500;
    color: #212121;
    line-height: .9rem;
    cursor: pointer;
}

.first-section{
    width: 46%;
}

.first-part, .second-part, .third-part{
    width: 18%;
}

.first-part p, .second-part p, .third-part p{
    cursor: pointer;
}

.title{
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.3rem;
    color: #29292A;
}

.submainfdiv2 {
    background-color: rgb(255, 255, 255);
    text-align: center;
    font-size: 0.85rem;
    line-height: 2rem;
    margin: 0;
    padding: 0;
    width: 100%;
} 

@media screen and (max-width:990px){
    .submainfdiv{
        width: 90%;
    }
    .first-section {
        width: 33%;
    }

    .Section {
        padding: 2rem 1rem;
    }

    .first-part, .second-part, .third-part {
        width: 23%;
    }

    .footerTextP {
        font-size: 1rem;
        line-height: 1.2rem;
    }

    
    .title {
        font-size: 0.9rem;
    }

    .bottom p {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 475px){
    .submainfdiv{
        flex-direction: column;
        justify-content: center;
    }
    .subonediv{
        flex-direction: column;
        justify-content: center;
        width: 100%;
        text-align: center;
    }
    .subonediv2{
        width: 100%;
        text-align: center;
    }
    .subitemdiv{
        width: 100%;
        text-align: center;
    }
    .Section {
        display: block; 
        padding: 1rem 1rem;
    }

    .first-section, .first-part, .second-part, .third-part{
        width: 100%;
        text-align: center;
    }
    .footerTextP {
        font-size: .85rem;
        line-height: 1rem;
    }
    .title {
        font-size: 1.1rem;
        line-height: 1.2rem;
        padding-top: 1rem;
    }
    .bottom p {
        font-size: 1rem;
        line-height: 2rem;
    }
} 