.logomain{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.logomain2{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70%;
}
.logomain3{
    width: 80%;
}
.logomain4{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}