.mimg {
    width: 16rem;
    height: 12rem;
}

.mainImg {
    border: #FFFFFF 1.5px solid;
}

.mainImg:hover {
    cursor: pointer;
    border: #41630F 1.5px solid;
}

.mimg2:hover {
    cursor: pointer;
    border: #41630F 1.5px solid;
}

.PopDialog {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding: 0.5rem 0 2.5rem 0;

}

.icn {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 6px;
    color: #41630F;
    padding: 0.2rem;
}

.itemicondiv {
    padding: 0.4rem 0 0rem 1rem;
    background: none;
    border: none;
}

.itemicondiv:hover {
    cursor: pointer;
}

.leftColmn,
.rightColmn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 2rem;
}
.rightColmn {
    width: 46%;
}

.mainImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.popTextP,
.PopDialog h4 {
    margin: 0;
    line-height: 2rem;
    font-size: .9rem;
    color: black;
    padding: .3rem 0;
}

.rImgs {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.rowImages {
    display: flex;
    justify-content: space-between;
}

.mimg2 {
    width: 3.5rem;
    height: 3.5rem;
}

.hr {
    padding: 1.5rem 0;
    width: 16rem;
}

.hoverTextPdialogp {
    display: flex;
    align-items: center;
    font-weight: bold;
}
.hoverTextPdialogp:hover {
    color: #41630F;
    cursor: pointer;
}
.desStock {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.desStock h5 {
    width: 12rem;
    font-size: .9rem;
    line-height: 1.3rem;
    margin: 0;
    color: black;
}

.desStock span {
    color: #1FEE2D;
    font-size: .9rem;
    width: 4rem;
}

.favIcnpop,
.categoryGrnTxt {
    color: #41630F;
}

.favouritepop {
    width: 2.5rem;
}

.favouriteLine,
.QtyLine {
    display: flex;
    align-items: center;
    height: 3.5rem;
}

.QtyLine {
    width: 7rem;
}

.qtyTextP {
    padding-right: 1rem;
}

.favTextP {
    padding-left: 1rem;
}

.fieldBtns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 0;
    width: 13.5rem;
}

.popbtns {
    width: 7rem;
    padding-bottom: 1rem;
}

.hoverTextP {
    font-size: .9rem;
}

.hoverTextP:hover {
    color: #41630F;
}

/* 
@media screen and (max-width:850px){
    .mainImg {
        width: 27vw;
        height: 17rem;
    }

    .rImgs {
        width: 6vw;
    }

    .popTextP, .PopDialog h4 {
        margin: 0;
        padding: 0;
        line-height: 2.5rem;
        font-size: 1rem;
    }

    .desStock h5 {
        font-size: 1.2rem;
        line-height: 1.6rem;
    }

    .desStock span {
        font-size: 1.2rem;
        width: 5rem;
    }
}

@media screen and (max-width:640px){
    .leftColmn {
        padding-right: 0;
    }

    .PopDialog {
        padding: 2rem;
        flex-direction: column;
        align-items: center;
    }

    .mainImg {
        width: 57vw;
        height: 10rem;
    }

    .hr {
        padding: 1.5rem 0;
        width: 18.7rem;
    }

}

@media screen and (max-width:437px){
    .leftColmn {
        padding-right: 0;
    }

    .PopDialog {
        padding: 2rem;
        flex-direction: column;
        align-items: flex-start;
    }

    .mainImg {
        width: 65vw;
        height: 9rem;
    }

    .rImgs {
        width: 15vw;
        padding-right: 0.5rem;
        padding-bottom: 3rem;
    }

    .rowImages {
        justify-content: flex-start;
    }

    .rightColmn {
        width: 16rem;
    }

    .desStock h5 {
        font-size: 1rem;
        line-height: 1.3rem;
    }

    .popTextP, .PopDialog h4 {
        line-height: 3.8rem;
        font-size: 1rem;
    }

    .hr {
        width: 15.3rem;
    }
} */
@media screen and (max-width:437px) {
    .PopDialog {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .rightColmn {
        width: 90%;
    }
}