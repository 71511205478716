body {
    background-color: white;
}

.top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 6vh;
}

.mailtext {
    margin: 0;
    padding: 0.7rem 0;
}

.location,
.mailicon {
    color: #41630F;
    padding: 0 0.3rem 0 0.6rem;
    height: 1.5rem;
    width: 1.5rem;
}

.mail {
    font-size: 0.9rem;
    color: #41630F;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
}
.call {
    font-size: 0.9rem;
    color: #41630F;
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
}
.items1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

hr {
    margin: 0;
}

.hrimg {
    margin-block-start: 0;
    margin-block-end: 0;
    border: 1px ridge #41630F;
}

.nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 9vh;
}

.list ul {
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.list li {
    list-style: none;
    cursor: pointer;
    padding: 0rem 1.37rem;
    color: #393E40;
    font: 0.9rem Roboto;
    font-weight: 600;
}

.home {
    text-decoration-line: underline;
    text-decoration-style: wavy;
    text-decoration-color: #41630F;
}

.navIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 20%;
}

.sameS {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.Icon {
    width: 2.2rem;
    color: #6A6E6F;
    cursor: pointer;
}

.Icon img {
    width: 1.3rem;
}

#check {
    display: none;
}

.checkbtn {
    font-size: 1rem;
    cursor: pointer;
    display: none;
}

.liItems {
    background-color: #F5F5F5;
    padding: 0;
    margin: 0;
    display: flex;
}

.logo {
    width: auto;
    height: 9vh;
    object-fit: cover;
}

.logo:hover {
    cursor: pointer;
}

.logo img {
    width: auto;
    height: 100%;
}

.dropdown {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.dropdown li {
    text-decoration: none;
    font-size: 1.1rem;
    color: rgba(29, 26, 26, 0.83);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    padding: .5rem;
}

.dropdown hr {
    width: 19rem;
    border-block-color: white;
}

.semail {
    display: none;
}
.semail a{
    text-decoration: none;
    color: #41630F;
    display: flex; 
    align-items: center;
}

@media screen and (max-width:820px) {
    .checkbtn {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .items1 {
        width: 45%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .logo {
        height: 7vh;
    }

    .list,
    .navIcon {
        display: none;
    }

    .dropdown hr {
        width: 43rem;
    }

    .dropdown li {
        font-size: 1.8rem;
        padding-top: 1.2rem;
    }

    nav hr {
        margin: 0;
    }

    .Icon {
        width: 1.7rem;
    }

    .Icon img {
        width: 1.2rem;
        height: 1.6rem;
    }
}

@media screen and (max-width:590px) {
    .nonem {
        display: none;
    }
    .call {
        display: flex;
    }

    .list,
    .navIcon {
        display: none;
    }

    .dropdown {
        padding: 1rem;
    }

    .dropdown li {
        font-size: 1rem;
        padding-top: .5rem;
    }

    .dropdown hr {
        width: 22rem;
    }

    .menu .css-i4bv87-MuiSvgIcon-root {
        width: 3em;
        height: 1.2em;
    }

    .nav {
        align-items: center;
    }

    nav hr {
        margin: 0;
    }

    .Icon img {
        width: 1.3rem;
    }

    .logo {
        height: 5vh;
    }


}