.partners{
    justify-content: center;
    padding: 0 0 3rem 0;
}

.prtLogo{
    padding-left: 4rem;
}

.prtLogo img{
    width: 6rem;
    height: 6rem;
    object-fit: contain;
}

@media screen and (max-width:820px){
    .prtLogo {
        padding-left: 1rem;
    }

    .prtLogo img {
        width: 5.6rem;
        height: 4rem;
        object-fit: contain;
    }
    .partners{
        padding: 3rem 0;
    }
    
    
}

@media screen and (max-width:475px){
    .partners{
        padding: 3rem 0rem;
    }

    .prtLogo img {
        width: 3.5rem;
        height: 3rem;
    }
}