.productspace{
    padding-left: 4rem;
    padding-right: 4rem;
}
.product-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem 1rem 0;
    width: 100%;
}
.producttopic{
    padding: 1rem;
}
.product-heading1{
    font-size: 2rem;
    color: #393E40;
    line-height: 2rem;
    font-weight: bold;
    margin: 0;
    padding-bottom: 2rem;
}
.product-button{
    background-color: #F5F5F5;
    color: #29292a;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #FFFFFF;
    cursor: pointer;
}
.picon{
    font-size: 1.2rem;
    color: #F5F5F5;
}
.button-group {
    width: 100%;
}
.filter-group{
    width: 100%;
}
.product-button:focus {
    background-color: #41630F;
    color: #FFFFFF;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.product-heading2{
    font-size: 1.2rem;
    color: #29292a;
    line-height: 2rem;
    font-weight: bold;
}
.product-price{
    font-size: 0.85rem;
    color: #29292a;
    line-height: 1.4rem;
}
.lkr{
    font-weight: bold;
}
@media screen and (max-width:820px){
    .productspace{
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
@media screen and (max-width:500px){
    .productspace{
        padding-left: 0.7rem;
        padding-right: 0.7rem;
    }
}
/* .product-grid{
    padding: 1rem;
} */
